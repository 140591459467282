import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlertComponent } from "./components/alert/alert.component";
import { AutocompleteFilterComponent } from "./components/autocomplete-filter/autocomplete-filter.component";
import { AutocompleteMultiselectComponent } from "./components/autocomplete-multiselect/autocomplete-multiselect.component";
import { ConfirmDialogComponent } from "./components/dialog/confirm-dialog/confirm-dialog.component";
import { FileUploadDialog } from "./components/dialog/file-upload-dialog/file-upload-dialog.component";
import { FileUploaderComponent } from "./components/file-uploader/file-uploader.component";
import { FormDialogComponent } from "./components/dialog/form-dialog/form-dialog.component";
import { InputDialog } from "./components/dialog/input-dialog/input-dialog.component";
import { RadioSelectDialog } from "./components/dialog/radio-select-dialog/radio-select-dialog.component";
import { SelectDialog } from "./components/dialog/select-dialog/select-dialog.component";
import { ServiceSelectDialog } from "./components/dialog/service-select-dialog/service-select-dialog.component";
import { UploadingDialogComponent } from "./components/dialog/uploading-dialog/uploading-dialog.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FormGroupBookmarksComponent } from "./components/form-group-bookmarks/form-group-bookmarks.component";
import { HeaderComponent } from "./components/header/header.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { NavComponent } from "./components/nav/nav.component";
import { NotifComponent } from "./components/notif/notif.component";
import { NotifListComponent } from "./components/notif-list/notif-list.component";
import { MaterialModule } from "../material.module";
import { ErrorComponent } from "./pages/error/error.component";
import { AppTranslateModule } from "../translate.module";
import { NgxLoadingModule } from "@dchtools/ngx-loading-v18";
import { FormsModule, NgControl, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { TranslateIsoPipe } from "./utils/translate-iso.pipe";
import { HttpClientModule } from "@angular/common/http";
import { AutocompleteFilterColorsGroupsComponent } from "../project-creation/components/project-colors/autocomplete-filter-colors-groups/autocomplete-filter-colors-groups.component";
import { RouterModule } from "@angular/router";
import { AutocompleteFilterModelComponent } from "./components/autocomplete-filter-model/autocomplete-filter-model.component";
import { AutocompleteFilterModelOldComponent } from "./components/autocomplete-filter-model__old/autocomplete-filter-model__old.component";
import { TextareaAutoresizeDirective } from "./directives/textarea-autoresize.directive";
import { DynamicDisableDirective } from "./directives/dynamic-disable.directive";
import { ListItemDialogComponent } from "./components/dialog/list-item-dialog/list-item-dialog.component";
import { MultiInputDialog } from "./components/dialog/multi-input-dialog/multi-input-dialog.component";
import { NoSpacesDirective } from "./directives/no-spaces.directive";
import { ChipsListComponent } from "./components/chips-list/chips-list.component";
import { ChipsListObjectComponent } from "./components/chips-list-object/chips-list-object.component";
import { QuillModule } from "ngx-quill";



@NgModule({
    declarations: [
        AlertComponent,
        AutocompleteFilterComponent,
        AutocompleteFilterModelComponent,
        AutocompleteFilterModelOldComponent,
        AutocompleteMultiselectComponent,
        AutocompleteFilterColorsGroupsComponent,
        ConfirmDialogComponent,
        FileUploadDialog,
        FileUploaderComponent,
        FormDialogComponent,
        InputDialog,
        RadioSelectDialog,
        SelectDialog,
        ServiceSelectDialog,
        ListItemDialogComponent,
        MultiInputDialog,
        UploadingDialogComponent,
        FooterComponent,
        FormGroupBookmarksComponent,
        HeaderComponent,
        LoaderComponent,
        NavComponent,
        NotifComponent,
        NotifListComponent,
        TranslateIsoPipe,
        ErrorComponent,
        TextareaAutoresizeDirective,
        DynamicDisableDirective,
        NoSpacesDirective,
        ChipsListComponent,
        ChipsListObjectComponent
    ],
    imports: [
        AppTranslateModule.translateForRoot(),
        RouterModule.forChild([]),
        CommonModule,
        FormsModule,
        MaterialModule,
        HttpClientModule,
        AppTranslateModule,
        NgxLoadingModule,
        ReactiveFormsModule,

        MatNativeDateModule,
        RxReactiveFormsModule,
        // CKEditorModule,
        QuillModule.forRoot({
            theme: 'bubble',
            bounds: '#prod-texts',
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],          // toggled buttons
                    [{ 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],        // superscript/subscript
                    [{ 'direction': 'rtl' }],                           // text direction
                    ['clean'],                                          // remove formatting button
                ],
            },
        }),
    ],
    exports: [
        //IMPORTS
        AppTranslateModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        HttpClientModule,
        AppTranslateModule,
        NgxLoadingModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        RxReactiveFormsModule,
        CKEditorModule,

        // COMPONENTS, DIRECTIVE, PIPES
        AlertComponent,
        AutocompleteFilterComponent,
        AutocompleteFilterModelOldComponent,
        AutocompleteFilterModelComponent,
        AutocompleteMultiselectComponent,
        AutocompleteFilterColorsGroupsComponent,
        ConfirmDialogComponent,
        FileUploadDialog,
        FileUploaderComponent,
        FormDialogComponent,
        InputDialog,
        RadioSelectDialog,
        SelectDialog,
        ServiceSelectDialog,
        ListItemDialogComponent,
        MultiInputDialog,
        UploadingDialogComponent,
        FooterComponent,
        FormGroupBookmarksComponent,
        HeaderComponent,
        LoaderComponent,
        NavComponent,
        NotifComponent,
        NotifListComponent,
        TranslateIsoPipe,
        ErrorComponent,
        TextareaAutoresizeDirective,
        DynamicDisableDirective,
        NoSpacesDirective,
        ChipsListObjectComponent,
        ChipsListComponent,
        QuillModule
    ],
    providers: [],
    bootstrap: []
})
export class SharedModule { }
