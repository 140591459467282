import { rx } from 'feathers-reactive';
import { Application, Service } from '@feathersjs/feathers';
import { environment } from 'src/environments/environment';
import feathers, { authentication, rest } from '@feathersjs/client';
import io, { Socket } from 'socket.io-client';
import axios from 'axios';
import { socketio } from '@feathersjs/client';
import { isPlatformBrowser } from '@angular/common';
import { Transport } from '@feathersjs/rest-client';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

/**
 * Simple wrapper for feathers
 */
@Injectable({
    providedIn: 'root',
})
export class KFeathers {

    protected _feathers: Application<any>;                                 // init feathers
    protected _socket?: Socket = undefined;
    protected _rest: Transport<any>;

    get api(): string { return environment.api.url; }

    get app(): Application { return this._feathers; };

    /** Expose socket to directly recieve event (if the default client is REST and NOT socket) 
         * if socket is default client use : app.service('adc').on('created', callback);
         * if REST is default client use : socket.on("abc created", () => console.log('abc created'));
         * */
    get socket() {
        return this._socket;
    }

    protected _isBrowser: boolean = false;
    get isBrowser(): boolean { return this._isBrowser; }

    constructor(@Inject(PLATFORM_ID) platformID: string) {
        this._isBrowser = isPlatformBrowser(platformID);
        console.log('[PLATFORM ID]', platformID, this.isBrowser);

        this._feathers = feathers();                                 // init feathers
        this._rest = rest(this.api);

        if (this.isBrowser) {
            this._socket = io(this.api, { // init socket.io
                transports: ['websocket'],
                rejectUnauthorized: false,
            });
        }

        try {
            let app;
            if (environment.transport === 'socket' && this._socket) {
                app = this._feathers.configure(socketio(this._socket, {
                    timeout: 20 * 60 * 1000, // 20 mins
                }));
            } else {
                app = this._feathers.configure(this._rest.axios(axios));
            }
            app.configure(authentication({                     // add authentication plugin
                storage: localStorage,
                storageKey: `${environment.slug}-jwt`,
            }))
                .configure(rx({                                  // add feathers-reactive plugin
                    idField: '_id'
                }));
        } catch (err) {
            console.log("ERROR", (err as any).message);
        }
    }

    // expose app gettter 
    public get(name: string) {
        return this._feathers.get(name);
    }

    // expose services
    public service<T>(name: string): Service<T> {
        return this._feathers.service(name);
    }

    // expose authentication
    public authenticate(credentials?: any): Promise<any> {
        return this._feathers.authenticate(credentials);
    }

    /**
     * Shortcut for socket.on
     * @param event event name as 'service event' 
     * @param fn callback function
     * @returns SocketIOClient.Emitter
     */
    public on(event: string, fn: (...args: any[]) => void) {
        if (this.socket) {
            return this.socket.on(event, fn);
        }
        return undefined;
    }

    public off(event: string) {
        if (this.socket) {
            return this.socket.off(event);
        }
        return undefined;
    }

    public removeListener(event: string) {
        console.log('REMOVE LISTENER');
        if (this.socket) {
            this.socket.removeListener(event);
        }
    }

    public emit(event: string, service: string, args: any, cb?: (err: any, result: any) => void | Promise<void>) {
        if (this.socket) {
            this.socket.emit(event, service, args, cb);
        }
    }

    // expose logout
    public logout() {
        return this._feathers.logout();
    }
}
