import { Injectable } from '@angular/core';

import { ComponentCanDeactivate } from './can-deactivate.interface';
import { Observable } from 'rxjs/internal/Observable';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../components/dialog/confirm-dialog/confirm-dialog.component';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class CanDeactivateGuard  {

    constructor(protected dialog: MatDialog) { }

    async buildDialog() {
        let dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'WARNING',
                text: 'UNSAVED_CHANGES'
            }
        });
        return <boolean>await firstValueFrom(dialog.afterClosed());
    }


    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> | Promise<boolean> {
        console.log('COMPONENT', component);
        return (component?.canDeactivate && component?.canDeactivate()) ? this.buildDialog() : true;
    }
}