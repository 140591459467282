<div class="container !py-0 !px-8 border-b-[1px] border-tw-lightgrey flex h-[5rem] justify-between" #containerHeader>
    <div class="flex gap-4 justify-start items-center h-full">
        <a (click)="removeFilterDashboard()" href="/"><img class="w-36 max-w-36 max-h-[5rem] " src="assets/svg/LogoDiadem.svg" alt="logo-diadem"></a>
        <ng-container *ngIf="isClient && urlLogoClient.length === 1">
            <ng-container *ngFor="let logo of urlLogoClient">
                <mat-divider vertical class="h-[3rem]"></mat-divider>
                <img class="w-full max-h-[3rem]" [src]="logo">
            </ng-container>
        </ng-container>
    </div>
    <app-nav [userFullName]="userFullName" [userEasydiadem]="userEasydiadem" class="flex h-full"></app-nav>
</div>
<div *ngIf="easyDiademRoute()" class="easydiadem-header sticky top-0 z-[60] h-[4rem]" [ngClass]="isClient ? 'blue' : 'red'">
    <a [routerLink]="'/easydiadem/dashboard'">
        <div class="flex gap-2 justify-start items-center h-full container" #easydiademHeader>
            <mat-icon [svgIcon]="'ed-hand'" class="!w-[2rem] !h-[2rem] text-white"></mat-icon>
            <h2 class="m-0 text-white !text-[1.75rem] font-bold">Easy Diadem</h2>
        </div>
    </a>
</div>